import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';


const firebaseConfig = {
    apiKey: "AIzaSyBgl19jEdx85UhgD5vrAXii8-o99xdST2U",
    authDomain: "marine-center-database.firebaseapp.com",
    databaseURL: "https://marine-center-database-default-rtdb.firebaseio.com",
    projectId: "marine-center-database",
    storageBucket: "marine-center-database.appspot.com",
    messagingSenderId: "66934899393",
    appId: "1:66934899393:web:a588b9d1a06371dbd40a40",
    measurementId: "G-X43R3WLPE7"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const database = getDatabase(app);
const functions = getFunctions(app);
const messaging = getMessaging(app);

export { app, auth, database, functions, messaging };
